import { EyeOff } from "react-feather";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";

export const Login = () => {
  return (
    <Layout>
      <form>
        <div className="text-center">
          <p className="my-4 font-bold text-2xl">Log in</p>
          <p className="my-4 font-bold">
            Bringing the real world classroom experience at your desktop
          </p>
        </div>
        <div className="space-y-2">
          <p>
            Do you have an account? &nbsp;
            <Link className="font-bold hover:underline" to="/register">
              Register
            </Link>
          </p>
          <p className="font-semibold">Username or Email</p>
          <input
            type="text"
            placeholder="Example@gmail.com"
            className="bg-white w-full p-2 focus:outline-none"
          />
          <p className="font-semibold">Password</p>
          <div className="flex flex-row bg-white my-4 items-center">
            <input
              type="text"
              placeholder="Password"
              className="w-full p-2 focus:outline-none"
            />
            <EyeOff className="text-gray-500 mx-2" />
          </div>
          <p className="hover:underline">
            <Link className="font-bold" to="/recover-password">
              Forgot Password?
            </Link>
          </p>
          <button
            type="submit"
            className="bg-cyan-900 w-full py-1 text-white font-bold"
          >
            Submit
          </button>
        </div>
      </form>
    </Layout>
  );
};
