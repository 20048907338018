/* eslint-disable react/jsx-pascal-case */
import Register_bg from "../../../assets/images/register_bg.png";
import { Navbar } from "../../../components/layout/component/Navbar";
import { Custform } from "../components/Custform";

export const Register = () => {
  return (
    <div className="">
      <Navbar page="Sign in" btnUrl="/login" current="registerPage" />
      <div className="w-full">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-4">
            <img src={Register_bg} alt="mic" className="bg-cover" />
          </div>
          <Custform />
        </div>
      </div>
    </div>
  );
};
