/** @format */

import { ForgotPass } from "../features/accounts/routes/ForgotPass";
import { NewPass } from "../features/accounts/routes/NewPass";
import { Register } from "../features/register/routes/Register";
import { Login } from "../features/accounts/routes/Login";
import  {Home}  from "../features/angella-katatumba";

export const publicRoutes = [
	{
		path: "/login",
		element: <Login />,
	},
	{
		path: "/register",
		element: <Register />,
	},
	{
		path: "/recover-password",
		element: <ForgotPass />,
	},
	{
		path: "/new-password",
		element: <NewPass />,
	},
	{
		path: "/angella-katatumba",
		element: <Home />,
	},
];
