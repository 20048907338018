/** @format */

import React from "react";
import { Footer } from "../../../components/layout/component/Footer";
import masterLogo from "../../../assets/images/masters-logo.PNG";
import { AiOutlineMenu } from "react-icons/ai";
import profilePic from "../../../assets/profile-picture.JPG";
// import backgroundImage from "../../../assets/bg.JPG";
import { BsPlay, BsShare } from "react-icons/bs";
import { SiApplemusic } from "react-icons/si";

import albumArt from "../../../assets/albumart.JPG";

export const Home = () => {
  const songs = [
    {
      number: 1,
      title: "Mufele",
      artist: "Angella Katatumba",
      time: "2:21",
      share: <BsShare />,
    },
    {
      number: 2,
      title: "Wendi",
      artist: "Angella Katatumba",
      time: "3:43",
      share: <BsShare />,
    },
    {
      number: 3,
      title: "Tonelabila",
      artist: "Angella Katatumba",
      time: "3:26",
      share: <BsShare />,
    },
    {
      number: 4,
      title: "Emotional",
      artist: "Angella Katatumba",
      time: "4:19",
      share: <BsShare />,
    },
    {
      number: 5,
      title: "Love",
      artist: "Angella Katatumba",
      time: "3:16",
      share: <BsShare />,
    },
  ];

  return (
    <>
      <div>
        <div
          className="relative"
          style={{
            backgroundAttachment: "fixed",
            backgroundImage: `url(${profilePic})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div
            style={{ backgroundColor: "#000000e0" }}
            className="pt-2 px-8 text-white py-12"
          >
            <nav className=" flex flex-wrap items-center justify-between px-2 py-3 ">
              <div className="container md:px-20 mx-auto flex  items-center justify-between">
                <div>
                  <a href="/">
                    <img
                      src={masterLogo}
                      alt="masters-ug-logo"
                      className="w-2/12 md:w-1/12"
                    />
                  </a>
                </div>
                <div>
                  <AiOutlineMenu className="text-white text-2xl cursor-pointer" />
                </div>
              </div>
            </nav>
            <div className="mt-10 ">
              <div className="flex flex-col md:grid md:grid-cols-2 ">
                <div className="flex">
                  <div className="hidden md:flex h-fit p-1 -rotate-90 mt-48 ml-10">
                    <div>
                      <span className="font-bold text-lg text-green-500">
                        Masters
                      </span>
                      <span className="font-bold text-lg">
                        {" "}
                        - released October 05, 2022
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className=" w-fit md:-ml-36 ">
                      <img src={albumArt} alt="album-art" className="w-96" />
                    </div>
                    <div className="mt-2 md:hidden">
                      <span className="font-bold text-lg text-green-500">
                        Masters
                      </span>
                      <span className="font-bold text-lg">
                        {" "}
                        - released October 05, 2022
                      </span>
                    </div>
                  </div>
                </div>
                <div className="p-5 flex flex-col  ">
                  <div className="flex flex-row-reverse md:flex-col justify-end gap-5 md:gap-0  items-center md:items-start ">
                    <div>
                      <div className="">
                        <h1 className="uppercase text-xl md:text-5xl text-white font-bold">
                          masterpiece
                        </h1>
                      </div>
                      <div className="mt-1">
                        <h1 className="uppercase  text-sm md:text-2xl text-white font-bold">
                          Angella Katatumba
                        </h1>
                      </div>
                    </div>
                    <div className="md:mt-10">
                      <div className="h-16 w-16 md:w-20 md:h-20">
                        <img
                          src={profilePic}
                          alt="profile-pic"
                          className="rounded-full w-full h-full bg-cover bg-left bg-no-repeat"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-10">
                    <div className="flex gap-5">
                      <button className="px-3 md:px-5 py-2 md:py-3 font-bold bg-green-500 flex items-center gap-2">
                        <BsPlay className="text-xl" /> Listen Now
                      </button>
                      <button className="px-3 md:px-6 py-2 md:py-3 font-bold bg-transparent border flex items-center gap-2 ">
                        <BsShare />
                        Share
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" bg-slate-800">
          <div className="mx-2 md:mx-24 md:px-12 text-white pt-24 ">
            <div className="">
              <h1 className="uppercase  text-xl md:text-3xl font-bold ">
                stream it your way
              </h1>
            </div>
            <div className="border p-2 md:p-4 w-fit mt-5 flex gap-4 items-center">
              <SiApplemusic />
              <span className="uppercase font-bold">Player</span>
            </div>
            <div className="mt-7">
              <h1 className="text-2xl md:text-4xl uppercase font-bold ">Track List</h1>
            </div>
            {songs.map((song, index) => (
              <div key={index} className=" mt-5 py-4 grid grid-cols-5">
                <div className="font-bold md:text-xl  text-left uppercase">
                  {song.number}
                </div>
                <div className="font-bold md:text-xl text-left uppercase">
                  {song.title}
                </div>
                <div className="hidden md:flex md:text-xl text-left uppercase">
                  {song.artist}
                </div>
                <div className="font-bold md:text-xl text-right uppercase">
                  {song.time}
                </div>
                <div className="cursor-pointer font-extrabold md:text-xl uppercase flex items-end justify-end">
                  {song.share}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="pt-24 bg-slate-800">
          <Footer />
        </div>
      </div>
    </>
  );
};
