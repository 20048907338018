import { Navbar } from "../../../components/layout/component/Navbar";

export const Layout = ({ children }) => {
  return (
    <div className="bg-login-bg bg-cover bg-no-repeat flex flex-col h-screen">
      <Navbar page="Register" btnUrl="/register" />
      <div className="px-12 pt-16 md:px-24 lg:px-36 flex-1 h-full">
        <div className="flex flex-row-reverse h-[95%] items-center">
          <div className="w-full lg:w-5/12 bg-slate-100/75 px-8 lg:px-20 py-4 lg:py-14 rounded-md">
            {children}
          </div>
        </div>
        <div className="text-white">
          <p>
            <span className="font-semibold">Terms </span>and
            <span className="font-semibold"> Conditions </span>apply
          </p>
        </div>
      </div>
    </div>
  );
};
