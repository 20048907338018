import Course1 from "../../../assets/images/course1.png";
import Course2 from "../../../assets/images/course2.png";
import Star from "../../../assets/images/star.png";

export const Card = () => {
  const courses = [
    {
      image_url: Course1,
      university: "University of Edinbarg",
      title: "Fundamentals of Music Theory",
      skills: ["Music", "Analysis"],
      rating: "4.5",
      duration: "1-3",
    },
    {
      image_url: Course2,
      university: "University of Havard",
      title: "Music Engineering",
      skills: ["Music", "Analysis", "Rap"],
      rating: "4.5",
      duration: "1-3",
    },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 font-medium text-gray-800 my-8">
      {courses.map((course, index) => (
        <div
          key={index}
          className="max-w-lg bg-white rounded-md shadow-lg mx-auto"
        >
          <div className="relative flex justify-center items-end">
            <img src={course.image_url} alt="course" className="bg-cover" />
            <p className="text-white font-semibold text-xl absolute my-4">
              {course.title}
            </p>
          </div>
          <div className="p-6 space-y-4">
            <div className="flex items-center">
              <div className="w-8 h-8 bg-black rounded-sm"></div>
              <p className="mx-4 font-bold">{course.university}</p>
            </div>
            <div>
              <p className="font-bold text-3xl">{course.title}</p>
            </div>
            <div className="flex">
              <p className="font-bold">Skills you'll gain:</p>
              <p>{course.skills.toString()}</p>
            </div>
            <div>
              <div className="flex items-center">
                <img src={Star} alt="star" className="h-6" />
                <p className="mx-2 font-semibold">
                  {course.rating}
                  <span className="font-normal">(1.5k reviews)</span>
                </p>
              </div>
              <div className="my-2">
                <p>
                  Beginner, Course <span>{course.duration} Months</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
