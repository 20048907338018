import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";

export const ForgotPass = () => {
  return (
    <Layout>
      <form>
        <p className="text-center my-4 font-bold text-2xl">Forgot Password</p>
        <p className="my-4 font-bold">
          Please enter email address associated with your account.
        </p>
        <p className="my-4 text-gray-500">
          You'll recieve a reset password link and confirmation OTP on this
          email.
        </p>
        <div className="space-y-4">
          <p className="font-semibold">Email address</p>
          <input
            type="text"
            placeholder="Example@gmail.com"
            className="bg-white w-full p-2 focus:outline-none"
          />
          <Link to="/new-password">
            <button
              type="submit"
              className="bg-cyan-900 w-full my-4 py-1 text-white font-bold"
            >
              Submit
            </button>
          </Link>
        </div>
      </form>
    </Layout>
  );
};
