import { Layout } from "../components/Layout";
import { EyeOff } from "react-feather";

export const NewPass = () => {
  return (
    <Layout>
      <form>
        <div className="text-center">
          <p className="my-4 font-bold text-2xl">Create new password</p>
          <p className="my-4 font-bold">
            Your new password must be different from previously used passwords
          </p>
        </div>
        <div className="space-y-2">
          <p className="font-semibold">New Password</p>
          <div className="flex flex-row bg-white my-4 items-center">
            <input
              type="text"
              placeholder="New Password"
              className="w-full p-2 focus:outline-none"
            />
            <EyeOff className="text-gray-500 mx-2" />
          </div>
          <p className="font-semibold">Confirm Password</p>
          <div className="flex flex-row bg-white my-4 items-center">
            <input
              type="text"
              placeholder="Confirm Password"
              className="w-full p-2 focus:outline-none"
            />
            <EyeOff className="text-gray-500 mx-2" />
          </div>
          <button
            type="submit"
            className="bg-cyan-900 w-full py-1 text-white font-bold"
          >
            Submit
          </button>
        </div>
      </form>
    </Layout>
  );
};
