import { Link } from "react-router-dom";
import RectBlock from "../../../assets/images/block.png";

export const Navbar = ({ page, btnUrl, current }) => {
  return (
    <>
      <div className="absolute w-full">
        <div className="px-12 md:px-24 lg:px-36 py-6">
          <nav className="flex flex-row justify-between">
            <Link to="/">
              <p className="font-bold text-white">Masterug</p>
            </Link>
            {/* registration text in navbar */}
            <div
              className={
                current === "registerPage" ? "flex flex-col" : "hidden"
              }
            >
              <p className="font-semibold text-2xl">Registration</p>
              <img src={RectBlock} alt="rectangle line" className="w-2/3" />
            </div>
            <Link to={btnUrl}>
              <p
                className={
                  current === "registerPage"
                    ? "py-2 px-6 font-semibold bg-cyan-900 text-white"
                    : "py-2 px-6 font-semibold bg-white"
                }
              >
                {page}
              </p>
            </Link>
          </nav>
        </div>
      </div>
    </>
  );
};
