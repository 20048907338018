export const Custform = () => {
  return (
    <form className="col-span-8 px-12 bg-white">
      <div className="grid grid-cols-2 gap-x-16 my-10">
        <div className="text-left space-y-2">
          <p className="font-semibold">Full Name</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your name"
          />
          <p className="font-semibold">Email</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your email"
          />
          <p className="font-semibold">Country</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your country"
          />
          <p className="font-semibold">Password</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your password"
          />
        </div>
        <div className="text-left space-y-2">
          <p className="font-semibold">Course you're applying for</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please select course"
          />
          <p className="font-semibold">Phone Number</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your phone number"
          />
          <p className="font-semibold">City</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please enter your country"
          />
          <p className="font-semibold">Confirm Password</p>
          <input
            className="w-full bg-gray-300 p-2 placeholder-gray-600"
            type="text"
            placeholder="Please confirm your password"
          />
        </div>
      </div>
      <div className="my-10 font-semibold">
        <p>Gender</p>
        <div className="flex flex-row space-x-4">
          <input type="radio" />
          <label>Male</label>
          <input type="radio" />
          <label>Female</label>
          <input type="radio" />
          <label>Other</label>
          <input type="radio" />
          <label>Prefer not to say</label>
        </div>
      </div>
      <div className="text-center">
        <button
          type="submit"
          className="bg-cyan-900 w-1/2 py-1 text-white font-bold"
        >
          Continue
        </button>
      </div>
    </form>
  );
};
