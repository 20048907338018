import { Link } from "react-router-dom";

export const Jumb = () => {
  return (
    <div className="bg-landing-nav bg-no-repeat bg-cover px-12 md:px-36 py-24">
      <div className="w-full lg:w-1/3">
        <p className="text-5xl font-semibold text-gray-300 my-10">
          Take your <span className="text-white font-bold">music</span>
          <br />
          <br />
          <span className="text-white font-bold">career</span> to the
          <br />
          <br /> next level
        </p>
        <Link to="/register">
          <button className="py-2 px-6 font-semibold bg-cyan-900 text-white">
            Register
          </button>
        </Link>
      </div>
    </div>
  );
};
