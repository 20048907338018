import { MainLayout } from "../../../components/layout/MainLayout";
import { Jumb } from "../components/Jumb";
import { Card } from "../components/Card";
import GirlImage from "../../../assets/images/landingPerson.png";
import Vector from "../../../assets/images/vector.png";

export const Landing = () => {
  return (
    <>
      <MainLayout page="Sign in" btnUrl="login">
        <Jumb />
        <div className="px-12 md:px-24 lg:px-36 py-4 bg-gray-200">
          {/* brief details about company */}
          <div className="flex flex-row-reverse h-12 my-2">
            <img src={Vector} alt="vector" />
          </div>
          <div className="grid grid-col-1 lg:grid-cols-2 gap-4 my-8">
            <img src={GirlImage} alt="girl questioning" className="h-5/6" />
            <div className="font-semibold text-lg space-y-4">
              <p className="font-bold text-xl">WHY YOU SHOULD LEARN WITH US</p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                magnam consequuntur praesentium quo voluptatem pariatur.
                Dignissimos ut sit necessitatibus amet, quasi ratione? Numquam
                voluptatem officiis soluta dicta. Nulla, quidem alias? ipsum
                dolor sit amet consectetur adipisicing elit.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eos
                magnam consequuntur praesentium quo voluptatem pariatur.
                Dignissimos ut sit necessitatibus amet, quasi ratione? Numquam
                voluptatem officiis soluta dicta. Nulla, quidem alias?
              </p>
            </div>
          </div>
          {/* course cards */}
          <Card />
        </div>
      </MainLayout>
    </>
  );
};
