import { Facebook, Instagram, Mail, Twitter } from "react-feather";
import logo from "../../../assets/images/logo.png";

export const Footer = () => {
  return (
    <>
      <div className="py-4 px-12 md:px-24 lg:px-36 bg-slate-800 text-white">
        <div className="py-8 grid grid-cols-2 md:grid-cols-4 gap-4">
          {/* contact us section */}
          <div>
            <p className="font-semibold my-4">Contact us</p>
            <ul className="space-y-3">
              <li>+256 700 000 000</li>
              <li>info@mastersug.com</li>
              <li>123 Ave, Kololo, Kampala</li>
            </ul>
          </div>
          {/* services section */}
          <div>
            <p className="font-semibold my-4">Our Services</p>
            <ul className="space-y-3">
              <li>Home</li>
              <li>About</li>
              <li>Studios</li>
              <li>E-Learning</li>
              <li>Contact Us</li>
            </ul>
          </div>
          {/* Quick links */}
          <div>
            <p className="font-semibold my-4">Quick Links</p>
            <ul className="space-y-3">
              <li>Register</li>
              <li>Knowledge Base</li>
              <li>FAQ</li>
              <li>Contact</li>
            </ul>
          </div>
          {/* Social page */}
          <div>
            <p className="font-semibold my-4">Our Socials</p>
            <p className="my-3">
              Keep in touch with us on all <br />
              Social media platforms
            </p>
            <div className="flex justify-between">
              <Facebook />
              <Twitter />
              <Instagram />
              <Mail />
            </div>
          </div>
        </div>
        <div className="md:flex justify-between space-y-4">
          <p className="inline-block">Copyright 2020 All rights reserved</p>
          <p className="inline-block">
            Powered by &nbsp;{" "}
            <img src={logo} alt="logo" className="inline-block" />
          </p>
        </div>
      </div>
    </>
  );
};
