import { Footer } from "./component/Footer";
import { Navbar } from "./component/Navbar";

export const MainLayout = ({ children, page, btnUrl }) => {
  return (
    <div>
      <Navbar page={page} btnUrl={btnUrl} className="pb-10" />
      {children}
      <Footer />
    </div>
  );
};
